<template>
  <div class="tag-container">
    <el-tag
      v-for="(filterFieldValue) in filterData"
      :key="`${filter.name}-${filterFieldValue.key}`"
      closable
      size="small"
      class="filter-tag"
      @close="() => {
        $emit('tagClosed', { name: filter.name, item: filterFieldValue })
      }"
    >
      <span v-if="filter.type === 'dateDay' || filter.type === 'dateString'">
        {{ filterFieldValue.label | date('DD MMMM YYYY') }}
      </span>
      <span v-else>
        {{ filterFieldValue.label }}
      </span>
    </el-tag>
  </div>
</template>

<script>

export default {
  name: 'FilterTags',
  props: {
    filter: { type: Object, default: () => {} },
    filterData: { type: Array, default: () => [] },
  },
};
</script>

<style lang="scss">
.tag-container {
  min-height: 30px;

  .filter-tag {
    margin: 0 4px 4px 0;
  }
}
</style>
