<template>
  <el-form
    v-if="filterFields.length"
    size="small"
    label-position="top"
    class="filter-form"
  >
    <el-row
      :gutter="20"
      type="flex"
    >
      <el-col
        v-for="(filter, index) in filterFields"
        :key="index"
        :span="8"
      >
        <el-form-item>
          <span slot="label">
            {{ $t(`filters.${filter.label}`) }}
            <InfoTooltip
              v-if="filter.help"
              :tooltip-content="$t(filter.help)"
            />
          </span>

          <el-input
            v-if="filter.type === 'text'"
            v-model="filterModel[filter.name]"
            type="text"
            @change="(value) => {
              addFilterData({ name: filter.name, item: {key: value, label: value}})
            }"
          />

          <el-input
            v-if="filter.type === 'number'"
            v-model="filterModel[filter.name]"
            type="number"
            :min="filter.min"
            :max="filter.max"
            @change="(value) => {
              addFilterData({ name: filter.name, item: {key: value, label: value}})
            }"
          />

          <el-select
            v-if="filter.type === 'select'"
            v-model="filterModel[filter.name]"
            placeholder=""
            clearable
            @change="(item) => {
              addFilterData(
                {
                  name: filter.name,
                  item: {key: item.value, label: $t(`${item.label}`)}
                })
            }"
          >
            <el-option
              v-for="(option) in filter.options"
              :key="option.value"
              :label="$t(`${option.label}`)"
              :value="option"
            />
          </el-select>

          <el-date-picker
            v-if="filter.type === 'dateDay' || filter.type === 'dateString'"
            v-model="filterModel[filter.name]"
            type="date"
            placeholder=""
            :format="filter.dateFormat"
            value-format="yyyy-MM-dd"
            @change="(value) => {
              addFilterData({ name: filter.name, item: {key: value, label: value}})
            }"
          />

          <el-date-picker
            v-if="filter.type === 'dateRange'"
            v-model="filterModel[filter.name]"
            type="daterange"
            range-separator="-"
            format="dd-MM-yyyy"
            value-format="yyyy-MM-dd"
            :start-placeholder="$t('filters.startDate')"
            :end-placeholder="$t('filters.endDate')"
            @change="(value) => {
              addFilterData({
                name: filter.name,
                item: {key: value, label: `${$filters.date(value[0])}-${$filters.date(value[1])}`}
              })
            }"
          />

          <el-autocomplete
            v-if="filter.type === 'autocomplete'"
            v-model="filterModel[filter.name]"
            value-key="label"
            clearable
            :fetch-suggestions="filter.autocompleteSearch"
            :trigger-on-focus="false"
            @select="(item) => {
              handleSelectAutocomplete(item, filter.name)
              addFilterData({ name: filter.name, item: {key: item.value, label: item.label}})
            }"
          />
        </el-form-item>

        <FilterTags
          :filter="filter"
          :filter-data="filterData[filter.name]"
          @tagClosed="({ name, item }) => {
            removeFilterData({ name, item })
            requestData()
          }"
        />
      </el-col>

      <el-col
        :span="8"
        class="filter-form-buttons"
      >
        <div class="filter-form-buttons__container">
          <el-form-item>
            <el-button
              type="danger"
              size="small"
              @click="resetFilterData"
            >
              {{ $t('filters.clearFilter') }}
            </el-button>
          </el-form-item>
        </div>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { mapGetters } from 'vuex';
import FilterTags from '@/components/FilterTags.vue';
import InfoTooltip from '@/components/InfoTooltip.vue';

export default {
  name: 'Filters',
  components: {
    FilterTags,
    InfoTooltip,
  },
  props: {
    filters: { type: Array, default: () => [] },
    filterKey: { type: String, default: '' },
    initialData: { type: Object, default: () => {} },
    hasPermissions: { type: Boolean, default: () => true },
  },
  data() {
    return {
      filterModel: {},
      filterData: { ...this.initialData },
      filterFields: [],
    };
  },
  computed: {
    ...mapGetters([
      'userPermissions',
    ]),
  },
  created() {
    // check if use permissions
    if (this.hasPermissions) {
      // filter columns by permissions
      this.filterFields = Object.values(this.filters)
        .reduce((offersFiltersAccumulator, currentFilter) => {
          if (currentFilter.permissions === undefined
            || currentFilter.permissions.length === 0
            || currentFilter.permissions
              .filter(value => [...this.userPermissions].includes(value)).length > 0) {
            return [...offersFiltersAccumulator, currentFilter];
          }
          return offersFiltersAccumulator;
        }, []);
    } else {
      this.filterFields = [...this.filters];
    }
  },
  methods: {
    addFilterData({ name, item }) {
      // Create property in filterData if missing
      if (!(name in this.filterData)) {
        this.$set(this.filterData, name, []);
      }

      // Add item in filterData[name] if missing
      if (!(this.filterData[name].find((filterDataItem) => {
        if (filterDataItem.key instanceof Date && item.key instanceof Date) {
          return filterDataItem.key.getTime() === item.key.getTime();
        }
        return filterDataItem.key === item.key;
      }))) {
        this.filterData[name] = [...this.filterData[name], item];
      }

      // clean filterModel
      this.filterModel = {};

      // request data
      this.requestData();
    },
    removeFilterData({ name, item }) {
      // remove item from filterData
      this.filterData[name] = this.filterData[name].filter((filterDataItem) => {
        if (filterDataItem.key instanceof Date && item.key instanceof Date) {
          return filterDataItem.key.getTime() !== item.key.getTime();
        }
        return filterDataItem.key !== item.key;
      });
    },
    requestData() {
      // Catch needed: see https://github.com/vuejs/vue-router/issues/2872#issuecomment-522341874
      const { page, ...queryWithoutPage } = this.$route.query;
      this.$router.replace({
        query: {
          ...queryWithoutPage,
          page: 1, // Fix for searching an element outside of the current page
          filterKey: this.filterKey,
          filterData: JSON.stringify(this.filterData),
        },
      }).catch(() => {});
    },
    resetFilterData() {
      this.filterModel = {};
      this.filterData = {};

      this.requestData();
    },
    handleSelectAutocomplete(item, name) {
      this.filterModel[name] = item.value;
    },
  },
};
</script>

<style lang="scss">
.filter-form {
  padding-bottom: 20px;
  min-height: $filter-form-min-height;

  .filter-form-buttons {
    display: flex;

    &__container {
      display: flex;
      margin-top: $filter-label-min-height;

      .el-form-item {
        margin-bottom: 0;
      }
    }
  }

  .el-form-item {
    &__label {
      color: $--color-primary;
      min-height: $filter-label-min-height;
    }

    &--small {
      .el-form-item {
        &__label {
          padding-bottom: 15px;
          line-height: 1;
          min-height: $filter-label-min-height;
        }
      }
    }

    .el-autocomplete {
      width: 100%;
    }
  }
}
</style>
