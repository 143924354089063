<template>
  <div class="offers-index">
    <Filters
      :filters="offersFilters"
      filter-key="offers"
      :initial-data="filtersStatus.offers"
    />
    <TableWrapper
      :table-columns="offersColumns"
      table-key="offers"
      :enable-selection="false"
      @downloadOffer="downloadOffer"
      @seeDetail="seeDetail"
      @change="change"
      @reloadExcel="reloadExcel"
      @disable="enableDisableOffer"
      @enable="enableDisableOffer"
    />
  </div>
</template>

<script>
import fileSaver from 'file-saver';
import { mapGetters, mapActions } from 'vuex';
import { offersColumns, offersFilters } from '@/config';
import { offersApi } from '@/api';
import acl from '@/services/acl';
import TableWrapper from '@/components/TableWrapper.vue';
import Filters from '@/components/Filters.vue';
// import FloatingActionButton from '@/components/FloatingActionButton.vue';

export default {
  name: 'OffersIndex',
  components: {
    TableWrapper,
    Filters,
    // FloatingActionButton,
  },
  data() {
    return {
      offersColumns,
      offersFilters,
    };
  },
  computed: {
    ...mapGetters([
      'filtersStatus',
      'tableFilters',
    ]),
  },
  created() {
    this.parseQueryStringStatus(this.$route.query);
    this.tableRows();
  },
  beforeRouteUpdate(to, from, next) {
    this.parseQueryStringStatus(to.query);
    this.tableRows();
    next();
  },
  methods: {
    ...mapActions([
      'updateTableRows',
      'updateTableCount',
      'parseQueryStringStatus',
    ]),
    async tableRows() {
      try {
        const offersIndexResponse = await offersApi.index(this.tableFilters('offers'), this.filtersStatus.offers, true);
        const offersCountResponse = await offersApi.count(this.filtersStatus.offers, true);
        this.updateTableRows({ tableKey: 'offers', rows: offersIndexResponse.data });
        this.updateTableCount({ tableKey: 'offers', count: offersCountResponse.data.count });
      } catch (error) {
        throw error;
      }
    },
    async downloadOffer(row) {
      try {
        this.loading = true;
        const file = await offersApi.getOfferPdf(row.id);
        if (file.data) {
          return fileSaver.saveAs(file.data, 'filename.pdf');
        }
        this.loading = false;
        return file;
      } catch (error) {
        this.loading = false;
        return error;
      }
    },
    seeDetail(row) {
      this.$router.push({ name: 'showOffer', params: { id: row.id } });
    },
    change(row) {
      this.$router.push({ name: 'editOffer', params: { id: row.id } });
    },
    reloadExcel(row) {
      this.$router.push({ name: 'importOfferItems', params: { id: row.id } });
    },
    canUse(permissions, checkAll = false) {
      return acl.userCanUse(permissions, checkAll);
    },
    async enableDisableOffer(row) {
      try {
        await offersApi.patchOffer(row.id, { enabled: !row.enabled });
        this.tableRows();
        this.$notify({ showClose: true, type: 'success', message: this.$t('offer.update.success') });
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
  },
};
</script>

<style lang="scss">
  .offers-index {
    width: 100%;
  }
</style>
