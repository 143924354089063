var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.filterFields.length)?_c('el-form',{staticClass:"filter-form",attrs:{"size":"small","label-position":"top"}},[_c('el-row',{attrs:{"gutter":20,"type":"flex"}},[_vm._l((_vm.filterFields),function(filter,index){return _c('el-col',{key:index,attrs:{"span":8}},[_c('el-form-item',[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t(("filters." + (filter.label))))+" "),(filter.help)?_c('InfoTooltip',{attrs:{"tooltip-content":_vm.$t(filter.help)}}):_vm._e()],1),(filter.type === 'text')?_c('el-input',{attrs:{"type":"text"},on:{"change":function (value) {
            _vm.addFilterData({ name: filter.name, item: {key: value, label: value}})
          }},model:{value:(_vm.filterModel[filter.name]),callback:function ($$v) {_vm.$set(_vm.filterModel, filter.name, $$v)},expression:"filterModel[filter.name]"}}):_vm._e(),(filter.type === 'number')?_c('el-input',{attrs:{"type":"number","min":filter.min,"max":filter.max},on:{"change":function (value) {
            _vm.addFilterData({ name: filter.name, item: {key: value, label: value}})
          }},model:{value:(_vm.filterModel[filter.name]),callback:function ($$v) {_vm.$set(_vm.filterModel, filter.name, $$v)},expression:"filterModel[filter.name]"}}):_vm._e(),(filter.type === 'select')?_c('el-select',{attrs:{"placeholder":"","clearable":""},on:{"change":function (item) {
            _vm.addFilterData(
              {
                name: filter.name,
                item: {key: item.value, label: _vm.$t(("" + (item.label)))}
              })
          }},model:{value:(_vm.filterModel[filter.name]),callback:function ($$v) {_vm.$set(_vm.filterModel, filter.name, $$v)},expression:"filterModel[filter.name]"}},_vm._l((filter.options),function(option){return _c('el-option',{key:option.value,attrs:{"label":_vm.$t(("" + (option.label))),"value":option}})}),1):_vm._e(),(filter.type === 'dateDay' || filter.type === 'dateString')?_c('el-date-picker',{attrs:{"type":"date","placeholder":"","format":filter.dateFormat,"value-format":"yyyy-MM-dd"},on:{"change":function (value) {
            _vm.addFilterData({ name: filter.name, item: {key: value, label: value}})
          }},model:{value:(_vm.filterModel[filter.name]),callback:function ($$v) {_vm.$set(_vm.filterModel, filter.name, $$v)},expression:"filterModel[filter.name]"}}):_vm._e(),(filter.type === 'dateRange')?_c('el-date-picker',{attrs:{"type":"daterange","range-separator":"-","format":"dd-MM-yyyy","value-format":"yyyy-MM-dd","start-placeholder":_vm.$t('filters.startDate'),"end-placeholder":_vm.$t('filters.endDate')},on:{"change":function (value) {
            _vm.addFilterData({
              name: filter.name,
              item: {key: value, label: ((_vm.$filters.date(value[0])) + "-" + (_vm.$filters.date(value[1])))}
            })
          }},model:{value:(_vm.filterModel[filter.name]),callback:function ($$v) {_vm.$set(_vm.filterModel, filter.name, $$v)},expression:"filterModel[filter.name]"}}):_vm._e(),(filter.type === 'autocomplete')?_c('el-autocomplete',{attrs:{"value-key":"label","clearable":"","fetch-suggestions":filter.autocompleteSearch,"trigger-on-focus":false},on:{"select":function (item) {
            _vm.handleSelectAutocomplete(item, filter.name)
            _vm.addFilterData({ name: filter.name, item: {key: item.value, label: item.label}})
          }},model:{value:(_vm.filterModel[filter.name]),callback:function ($$v) {_vm.$set(_vm.filterModel, filter.name, $$v)},expression:"filterModel[filter.name]"}}):_vm._e()],1),_c('FilterTags',{attrs:{"filter":filter,"filter-data":_vm.filterData[filter.name]},on:{"tagClosed":function (ref) {
          var name = ref.name;
          var item = ref.item;

          _vm.removeFilterData({ name: name, item: item })
          _vm.requestData()
        }}})],1)}),_c('el-col',{staticClass:"filter-form-buttons",attrs:{"span":8}},[_c('div',{staticClass:"filter-form-buttons__container"},[_c('el-form-item',[_c('el-button',{attrs:{"type":"danger","size":"small"},on:{"click":_vm.resetFilterData}},[_vm._v(" "+_vm._s(_vm.$t('filters.clearFilter'))+" ")])],1)],1)])],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }